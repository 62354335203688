import React from 'react'
import { Helmet } from 'react-helmet'
import { PageData } from 'src/types'
import Footer from './footer'
import './layout.scss'
import MainNav from './main-nav'
import MetaTags from './meta-tags'

const Layout = ({
  title,
  description,
  imageUrl,
  children,
  pageData,
}: {
  title?: string
  description?: string
  imageUrl?: string
  pageData: PageData
  children: React.ReactNode
}) => {
  // Preview pages for new documents are missing settings and links from the pageData
  const { prismicSettings, prismicLinks } = pageData
  const settings = prismicSettings?.data
  const links = prismicLinks?.data

  return (
    <>
      {process.env.GATSBY_PRISMIC_PREVIEW_URL && (
        <Helmet>
          <script async defer src={process.env.GATSBY_PRISMIC_PREVIEW_URL} />
        </Helmet>
      )}
      {settings?.typekit_url && (
        <Helmet>
          <link rel="stylesheet" href={settings.typekit_url} />
        </Helmet>
      )}
      <MetaTags title={title} description={description} imageUrl={imageUrl} settings={settings} />
      <MainNav links={links} />
      {children}
      <Footer links={links} />
    </>
  )
}

export default Layout
