import classNames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'
import { ButtonProps } from 'react-bootstrap'
import ArrowRight from '../assets/arrow-right.svg'
import * as styles from './link-arrow.module.scss'

interface Props extends ButtonProps {
  children: React.ReactNode
  href: string
  className?: string
  textLarge?: boolean
  block?: boolean
  light?: boolean
  dark?: boolean
}

const LinkArrow = ({
  children,
  href,
  className,
  textLarge,
  block,
  light,
  dark,
  ...props
}: Props) => {
  const buttonVariant = light ? 'btn-outline-light' : 'btn-outline-dark'
  const classes = classNames(
    styles.linkArrow,
    'btn',
    light || dark ? buttonVariant : 'btn-primary',
    className,
    {
      [styles.textLarge]: textLarge,
      [styles.light]: light,
      'btn-block': block,
    }
  )

  const content = () => (
    <>
      {children}
      <div className={styles.icon}>
        <ArrowRight />
      </div>
    </>
  )

  if (!href.startsWith('/')) {
    return (
      <a href={href} className={classes} {...props}>
        {content()}
      </a>
    )
  }

  return (
    <Link to={href} className={classes} {...props}>
      {content()}
    </Link>
  )
}

export default LinkArrow
