import classNames from 'classnames'
import React from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import { contactPath } from 'src/paths'
import Logo from '../assets/brooklyn-bridge-ventures-logo.svg'
import Instagram from '../assets/instagram.svg'
import LinkedIn from '../assets/linkedin.svg'
import Twitter from '../assets/twitter.svg'
import { PrismicLinkNode, PrismicLinks } from '../types'
import * as styles from './footer.module.scss'
import LinkArrow from './link-arrow'
import { NavLinks } from './main-nav'

export const COPYRIGHT = `Copyright ©${new Date().getFullYear()} Brooklyn Bridge Ventures, Inc.`

export const FollowUs = ({ linkClassName }: { linkClassName: string }) => {
  return (
    <>
      <div className={styles.footerHeading}>Follow Us</div>
      <div>
        <a
          href="https://www.linkedin.com/company/brooklyn-bridge-ventures"
          target="blank"
          className={classNames(linkClassName, styles.footerSocialLink, 'link-dark')}
        >
          <LinkedIn />
        </a>
        <a
          href="https://twitter.com/bklynbridgevc"
          target="blank"
          className={classNames(linkClassName, styles.footerSocialLink, 'link-dark')}
        >
          <Twitter />
        </a>
        <a
          href="https://www.instagram.com/brooklynbridgevc"
          target="blank"
          className={classNames(linkClassName, styles.footerSocialLink, 'link-dark')}
        >
          <Instagram />
        </a>
      </div>
    </>
  )
}
const Footer = ({ links }: { links?: PrismicLinks }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const navLinks =
    links?.footer_navigation?.map((link: PrismicLinkNode) => link.link.document.data) || []

  const legalLinks =
    links?.footer_legal?.map((link: PrismicLinkNode) => link.link.document.data) || []

  return (
    <div>
      <div className={styles.footerTop}>
        <Container>
          <Row>
            <Col xs={12} md={5} lg={4}>
              <div className={styles.footerLogo}>
                <Logo />
              </div>
              <LinkArrow
                href={contactPath()}
                className="text-uppercase"
                textLarge={true}
                block={true}
              >
                Request a Meeting
              </LinkArrow>
            </Col>
            <Col xs={12} md={7} lg={8}>
              <Row className={styles.rightFooterRow}>
                <Col xs={6} md={4}>
                  <div className={styles.footerHeading}>Navigation</div>
                  <Nav className="footer-nav">
                    <NavLinks links={navLinks} />
                  </Nav>
                </Col>
                <Col xs={6} md={8}>
                  <Row>
                    {legalLinks.length > 0 && (
                      <Col sm={12} md={6}>
                        <div className={styles.footerHeading}>Legal</div>
                        <Nav className="footer-nav">
                          <NavLinks links={legalLinks} />
                        </Nav>
                      </Col>
                    )}

                    <Col sm={12} md={6}>
                      <FollowUs linkClassName="link-dark" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.footerBottom}>
        <Container>
          <Row>
            <Col xs={12} md={8}>
              <div>{COPYRIGHT} All Rights Reserved</div>
            </Col>
            <Col xs={12} md={4} className="d-flex">
              <button onClick={scrollToTop} className={styles.scrollToTopButton}>
                Free ride to the top
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Footer
