import React from 'react'
import { Helmet } from 'react-helmet'
import { PrismicSettings } from 'src/types'

const MetaTags = ({
  title,
  description,
  imageUrl,
  settings,
}: {
  title?: string
  description?: string
  imageUrl?: string
  settings?: PrismicSettings
}) => {
  const titleText = [title, settings?.title].filter((t) => t).join(' - ')
  const descriptionText = description || settings?.description
  const twitterHandle = `@${settings?.twitter_handle}`
  const image = `${imageUrl || settings?.meta_image?.url}&fit=crop&max-h=1000&max-w=1000`

  return (
    <Helmet>
      <title>{titleText}</title>
      <meta name="description" content="A seed stage investment fund based in Brooklyn, NY" />

      <meta itemProp="name" content={titleText} />
      <meta itemProp="description" content={descriptionText} />
      <meta itemProp="image" content={image} />

      <meta name="twitter:site" content={twitterHandle} />
      <meta name="twitter:creator" content={twitterHandle} />
      {!!image && <meta name="twitter:card" content={image} />}
      {!!image && <meta name="twitter:image:src" content={image} />}

      <meta property="og:title" content={titleText} />
      {!!image && <meta property="og:image" content={image} />}
      <meta property="og:description" content={descriptionText} />
      <meta property="og:site_name" content={settings?.title} />

      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  )
}

export default MetaTags
