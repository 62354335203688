import { useLocation } from '@reach/router'
import classNames from 'classnames'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import {
  contactPath,
  homePath,
  investmentsPath,
  portfolioPath,
  privacyPath,
  termsPath,
} from 'src/paths'
import ArrowRight from '../assets/arrow-right.svg'
import Logo from '../assets/brooklyn-bridge-ventures-logo.svg'
import MobileNav from '../assets/mobile-nav.svg'
import { PrismicLink, PrismicLinkNode, PrismicLinks } from '../types'
import { COPYRIGHT, FollowUs } from './footer'
import * as styles from './main-nav.module.scss'

const MOBILE_MENU_BODY_CLASS = 'mobile-menu-visible'

export const NavLink = ({
  to,
  activePaths = [],
  blank,
  header,
  className,
  icon,
  children,
}: {
  to: string
  activePaths?: string[]
  blank?: boolean
  header?: boolean
  className?: string
  icon?: React.ReactNode
  children: React.ReactNode
}) => {
  const { pathname } = useLocation()
  const active =
    to === homePath()
      ? pathname === homePath()
      : [to, ...activePaths].some((path) => pathname.startsWith(path))

  const classes =
    header && to === contactPath()
      ? classNames(
          className,
          'd-flex align-items-center btn btn-outline-dark',
          styles.contactButton
        )
      : classNames(className, 'd-flex align-items-center nav-link', { active })
  const target = blank ? 'blank' : undefined
  const linkContent = () => (
    <>
      <span className="nav-item-text">{children}</span>
      {icon}
    </>
  )

  if (!to.startsWith('/')) {
    return (
      <a href={to} className={classes}>
        {linkContent()}
      </a>
    )
  }

  return (
    <Link to={to} className={classes} target={target}>
      {linkContent()}
    </Link>
  )
}

export const NavLinks = ({ links, header }: { links: PrismicLink[]; header?: boolean }) => {
  return (
    <>
      {links.map((link, index) => (
        <NavLink
          key={index}
          to={link.url}
          blank={link.open_in_new_window}
          activePaths={link.url === investmentsPath() ? [portfolioPath()] : undefined}
          header={header}
        >
          {link.name}
        </NavLink>
      ))}
    </>
  )
}

export const MobileMenu = ({
  links,
  setShowMobileMenu,
}: {
  links: PrismicLink[]
  setShowMobileMenu: (state: boolean) => void
}) => {
  return (
    <div className={styles.mobileMenu}>
      <Navbar className={styles.mainNavbar}>
        <Container fluid={true}>
          <Navbar.Brand href="/">
            <Logo />
          </Navbar.Brand>
          <button
            className={classNames(styles.mobileNavButton, styles.mobileNavButtonOpen)}
            onClick={() => setShowMobileMenu(false)}
          >
            <MobileNav />
          </button>
        </Container>
      </Navbar>

      {links.map((link, index) => (
        <NavLink
          key={index}
          to={link.url}
          blank={link.open_in_new_window}
          activePaths={link.url === investmentsPath() ? [portfolioPath()] : undefined}
          className={styles.mobileNavLink}
          icon={
            <span className="ml-auto">
              <ArrowRight />
            </span>
          }
        >
          {link.name}
        </NavLink>
      ))}

      <Container fluid={true}>
        <br />

        <Link to={contactPath()} className="btn btn-primary btn-block text-uppercase mt-3">
          Request a meeting
        </Link>

        <br />

        <div className={styles.mobileMenuSection}>
          <Logo />
        </div>

        <div className={styles.mobileMenuSection}>
          <div>
            <FollowUs linkClassName="link-light" />
          </div>
        </div>

        <div className={styles.mobileMenuSection}>
          <NavLink to={termsPath()} className="link-light justify-content-center py-1">
            Terms & Conditions
          </NavLink>
          <NavLink to={privacyPath()} className="link-light justify-content-center py-1">
            Privacy Policy
          </NavLink>
        </div>

        <div className={classNames(styles.mobileMenuSection, styles.mobileMenuCopyright)}>
          {COPYRIGHT}
          <br />
          All Rights Reserved
        </div>
      </Container>
    </div>
  )
}

const MainNav = ({ links }: { links?: PrismicLinks }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const mainMenuLinks =
    links?.main_menu?.map((link: PrismicLinkNode) => link.link.document.data) || []
  const mobileMenuLinks =
    links?.footer_navigation?.map((link: PrismicLinkNode) => link.link.document.data) || []

  useEffect(() => {
    if (document && showMobileMenu && !document.body.classList.contains(MOBILE_MENU_BODY_CLASS)) {
      document.body.classList.add(MOBILE_MENU_BODY_CLASS)
    } else if (document && !showMobileMenu) {
      document.body.classList.remove(MOBILE_MENU_BODY_CLASS)
    }
  }, [showMobileMenu])

  return (
    <>
      <Navbar className={styles.mainNavbar}>
        <Container fluid={true} className={styles.mainNavContainer}>
          <Navbar.Brand href="/">
            <Logo />
          </Navbar.Brand>
          <Nav className="ml-auto align-items-center d-none d-lg-flex">
            <NavLinks links={mainMenuLinks} header={true} />
          </Nav>
          <button
            onClick={() => setShowMobileMenu((prev) => !prev)}
            className={classNames(
              'ml-auto align-items-center d-flex d-lg-none',
              styles.mobileNavButton
            )}
          >
            <MobileNav />
          </button>
        </Container>
      </Navbar>
      {showMobileMenu && (
        <div className="d-flex d-lg-none">
          <MobileMenu links={mobileMenuLinks} setShowMobileMenu={setShowMobileMenu} />
        </div>
      )}
    </>
  )
}

export default MainNav
